import type { LocationQuery } from "vue-router";

import store from "@/store";
import type {
  ConsumerData,
  ExpectType,
  RequiredType,
  Scenario,
} from "@/store/consumer.module";
import { inviteAccept /*, invite*/ } from "@/services/invite.service";
import {
  consumerById,
  consumerByMask,
  consumersList,
} from "@/services/consumers.service";
import { isValidRequiredType } from "@/store/consumer.module";
import { $notify } from "@/services/notification";

import { config } from "@/services/config";
import { UserWithPermissions } from "@/types/graphql";
import * as FullStory from "@/util/fullStory";

export const higherPriorityExpectation = (expectation: ExpectType): boolean => {
  // INFO: List of possible expectation from lower priority to higher
  const priorities: Array<ExpectType> = [
    "redirect",
    "permission",
    "impersonate",
    "complete",
    "invite",
  ];

  if (!store.getters.expect) {
    return true;
  } else {
    return (
      priorities.indexOf(expectation) >=
      priorities.indexOf(store.getters.expect)
    );
  }
};

export const getConsumerOnLogin = async (): Promise<
  ConsumerData | undefined
> => {
  const me: UserWithPermissions | undefined = store.getters.me;
  const consumers = await consumersList();

  if (me) {
    const consumer = consumers.find((consumer) =>
      me.permissions?.includes(`${consumer.id}:redirect`)
    );

    if (consumer && higherPriorityExpectation("permission")) {
      return {
        redirectTo: consumer.url,
        consumer: consumer.id,
        expect: "permission",
      };
    }
  }

  return undefined;
};

export const getConsumerData = async (
  query: LocationQuery
): Promise<ConsumerData | undefined> => {
  const scenario: Scenario = {};
  if (query.skipAssign === "true") scenario.skipConsumerPassing = true;

  // Set consumer by query param only if redirectTo is absent
  if (query.consumer && !query.redirectTo) {
    return {
      consumer: query.consumer as string,
      scenario,
    };
  }

  const redirectTo = query.redirectTo as string;

  // Set consumer and required attributes, stay on profile edit until user doesn't update them
  if (redirectTo && query.required) {
    const consumer = await consumerByMask(redirectTo);
    const required = (query.required as string)
      .split(",")
      .filter(isValidRequiredType) as Array<RequiredType>;

    if (
      consumer &&
      required.length > 0 &&
      higherPriorityExpectation("complete")
    ) {
      return {
        redirectTo,
        consumer: consumer?.id as string,
        expect: "complete",
        required,
        scenario,
      };
    }
  }

  // Set consumer if it's localhost
  if (
    redirectTo &&
    redirectTo.startsWith("http://localhost:") &&
    higherPriorityExpectation("redirect")
  ) {
    return {
      redirectTo,
      expect: "redirect",
      scenario,
    };
  }

  // Set consumer, default behavior
  if (redirectTo) {
    const consumer = query.consumer
      ? await consumerById(query.consumer as string)
      : await consumerByMask(redirectTo);

    const expect: ExpectType = (query.expect || "redirect") as ExpectType;

    if (consumer && higherPriorityExpectation(expect)) {
      return {
        redirectTo,
        consumer: consumer?.id as string,
        expect,
        scenario,
      };
    }
  }

  return undefined;
};

// export const getConsumerOnInvite = async (
//   query: LocationQuery
// ): Promise<ConsumerData | undefined> => {
//   const inviteId = query.invite as string;
//   const { email, requester, joincode } = await invite(inviteId);
//
//   if (email && joincode && requester && higherPriorityExpectation("invite")) {
//     return {
//       joincode,
//       inviteId,
//       inviteEmail: email,
//       inviteRequester: requester,
//       expect: "invite",
//     };
//   }
//
//   return undefined;
// };

export const redirectOnExpectation = async (expect: ExpectType) => {
  if (store.getters.redirectTo && store.getters.expect === expect) {
    await store.dispatch("status", "loading");

    const url = new URL(config.GRAPHQL_URI as string);
    const redirectTo = btoa(store.getters.redirectTo);

    await store.dispatch("clearConsumerData");
    await store.dispatch("status", "redirecting");

    location.replace(`${url.origin}/redirect?to=${redirectTo}`);
    // location.replace(store.getters.redirectTo);

    return false;
  }

  return true;
};

export const redirectOnComplete = async (): Promise<void> => {
  const me = store.getters.me;
  const required = store.getters.requiredAttributes || [];

  const isComplete = required.every(
    (attribute: RequiredType) => !!me[attribute]
  );

  if (isComplete) {
    await redirectOnExpectation("complete");
  }
};

export const redirectOnInvite = async (): Promise<void> => {
  const inviteId = store.getters.inviteId;

  if (inviteId) {
    const { redirectTo, error } = await inviteAccept(inviteId);
    const source = store.getters.inviteSource;

    store.commit("clearInvite");

    FullStory.event("Invite Accepted", {
      source,
    });

    if (!error && redirectTo) {
      await store.dispatch("setConsumeData", {
        redirectTo,
        expect: "invite",
      });

      await redirectOnExpectation("invite");
    } else if (error) {
      await store.dispatch("clearConsumerData");

      $notify({
        title: error.message,
        type: "danger",
      });
    }
  }
};
