import { apolloClient } from "@/providers/apollo.provider";

import type { InviteInfo, MutationInviteCreateArgs } from "@/types/graphql";

import InviteAcceptMutation from "@/graphql/inviteAccept.mutation.graphql";
import InviteCreateMutation from "@/graphql/inviteCreate.mutation.graphql";
import InviteQuery from "@/graphql/invite.query.graphql";
import MeQuery from "@/graphql/me.query.graphql";

export const invite = async (id: string): Promise<InviteInfo> => {
  return apolloClient
    .query({ query: InviteQuery, variables: { id } })
    .then(({ data }) => data.invite)
    .catch(() => ({}));
};

export const inviteAccept = async (
  id: string
): Promise<{ redirectTo?: string; error?: Error }> => {
  return apolloClient
    .mutate({
      mutation: InviteAcceptMutation,
      variables: { inviteId: id },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: MeQuery }],
    })
    .then(({ data }) => ({ redirectTo: data.inviteAccept?.redirect }))
    .catch((err) => ({ error: err }));
};

export const inviteCreate = async (
  variables: MutationInviteCreateArgs
): Promise<string> => {
  return apolloClient
    .mutate({ mutation: InviteCreateMutation, variables })
    .then(({ data }) => data.inviteCreate);
};
