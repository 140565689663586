import * as FS from "@fullstory/browser";

// @ts-ignore
import rateLimit from "function-rate-limit";

import store from "@/store";
import { config } from "@/services/config";

import { UserWithPermissions } from "@/types/graphql";

const identify = (user?: UserWithPermissions) => {
  if (user) {
    if (Array.isArray(user.parents)) {
      FS.FullStory("setIdentity", {
        uid: String(user.parents[0]),
        properties: {
          uid: user.parents[0],
          impersonation: true,

          email_impersonated: user.email as string,
          firstName_impersonated_str: user.firstName,
          lastName_impersonated_str: user.lastName,
          uid_impersonated: user.id,
        },
      });
    } else {
      FS.FullStory("setIdentity", {
        uid: String(user.id),
        properties: {
          uid: user.id,
          impersonation: false,

          displayName: `${user.firstName} ${user.lastName}`,
          email: user.email as string,
          firstName_str: user.firstName,
          lastName_str: user.lastName,
        },
      });
    }

    if (!config.IS_PRODUCTION) console.log(`[FS] Identify as ${user?.email}`);
  } else {
    FS.FullStory("setIdentity", { anonymous: true });
    if (!config.IS_PRODUCTION) console.log(`[FS] Identify as anonymous`);
  }
};

// INFO:
// https://help.fullstory.com/hc/en-us/articles/360020623234#Custom%20Property%20Rate%20Limiting
// https://help.fullstory.com/hc/en-us/articles/360020624394#Rate%20Limiting%20and%20Cardinality%20Limiting
const rateLimitedEvent = rateLimit(
  8,
  1000,
  (eventName: string, eventProperties: object = {}) => {
    config.IS_PRODUCTION
      ? FS.FullStory("trackEvent", {
          name: eventName,
          properties: eventProperties,
        })
      : console.log("[FS]", eventName, eventProperties);
  }
);

export const init = () => {
  if (!FS.isInitialized()) {
    FS.init({
      orgId: config.FULLSTORY_ORG_ID,
      debug: false,
      devMode: !config.IS_PRODUCTION,
      namespace: "__SNAP_FS__",
    });

    if (store && store?.getters?.isAuthorized) {
      identify(store.getters.me);
    }

    store?.subscribe((mutation) => {
      switch (mutation.type) {
        case "updateUserState":
          if (mutation.payload.id && mutation.payload.email) {
            identify(mutation.payload);
          } else {
            identify();
          }
          break;

        case "clearUserState":
          identify();
          break;
      }
    });
  }

  return FS.FullStory;
};

export const event = (
  eventName: string,
  eventProperties: object = {}
): void => {
  const payload = {
    ...eventProperties,
    path_str: location.pathname,
    url_str: location.href,
  };

  rateLimitedEvent(eventName, payload);
};

export const setPageProperties = (
  pageName: string,
  properties: object = {}
): void => {
  config.IS_PRODUCTION
    ? FS.FullStory("setProperties", {
        type: "page",
        properties: {
          pageName,
          ...properties,
        },
      })
    : console.log("[FS]", pageName, properties);
};
