import type { App } from "vue";
import type { Extras } from "@sentry/types";
import * as Sentry from "@sentry/vue";
import SentryFullStory from "@sentry/fullstory";
// import { Debug } from "@sentry/integrations";
import { excludeGraphQLFetch } from "apollo-link-sentry";

import store from "@/store";
import { config } from "@/services/config";
import * as FullStory from "@/util/fullStory";

export const init = (app: App) => {
  const client = FullStory.init();

  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      // new Debug({ stringify: true }),
      new SentryFullStory("snap-mobile-inc", { client }),
    ],

    environment: process.env.NODE_ENV,
    release: process.env.VUE_APP_VERSION,

    beforeBreadcrumb: excludeGraphQLFetch,

    tracesSampleRate: config.IS_PRODUCTION ? 0.2 : 1,
    enabled: config.IS_PRODUCTION,
    // debug: true,

    ignoreErrors: [
      "Importing a module script failed.",
      "Incorrect Password",
      "Input validation failed.",
      "The confirmation code you entered is not valid.",
      "The email and password combination you entered did not match our records. Please double-check and try again.",
      "The verification code you entered is not valid.",
      "User is not confirmed.",
      "User requires new password.",
      "User with this email is already registered. Please reset your password.",
      "Username or password is incorrect.",
      "Verification code is invalid.",
      "You exceeded your retry limit, try again later.",
      "Your permission to set a new password does not apply to this user.",
      /\.isProxied/i,
    ],
  });

  if (store && store?.getters?.isAuthorized) {
    Sentry.setUser({
      id: store.getters.me.id,
      email: store.getters.me.email,
      username: store.getters.me.fullName,
    });
  } else {
    Sentry.setUser(null);
  }
};

export const captureException = (e: Error, extras: Extras = {}) => {
  Sentry.withScope((scope) => {
    scope.setExtras(extras);
    Sentry.captureException(e);
  });
};

store?.subscribe((mutation) => {
  switch (mutation.type) {
    case "updateUserState":
      if (mutation.payload.id && mutation.payload.email) {
        Sentry.setUser({
          id: mutation.payload.id,
          email: mutation.payload.email,
          username: mutation.payload.fullName,
        });
      } else {
        Sentry.setUser(null);
      }
      break;

    case "clearUserState":
      Sentry.setUser(null);
      break;
  }
});
