import { NavigationGuard } from "vue-router";

import store from "@/store";

export const AccessGuard: NavigationGuard = async (to, from, next) => {
  if (to.meta.requiresAuth && store.getters.isWaitingForConfirmation) {
    return next({ name: "verify" });
  }

  if (to.meta.requiresAuth && !store.getters.isAuthorized) {
    return next({
      name: "login",
      query: {
        to: to.name !== "home" ? String(to.fullPath) : undefined,
      },
    });
  }

  if (!to.meta.requiresAuth && store.getters.isAuthorized) {
    return next({ name: "home" });
  }

  if (
    to.meta.hasEveryPermissions &&
    !store.getters.hasEveryPermissions(to.meta.hasEveryPermissions)
  ) {
    return next({ name: "home" });
  }

  if (
    to.meta.hasAnyPermissions &&
    !store.getters.hasAnyPermissions(to.meta.hasAnyPermissions)
  ) {
    return next({ name: "home" });
  }

  return next();
};
