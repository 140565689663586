<template>
  <snap-tabs
    :type="type || 'primary'"
    :options="options"
    @snap-tabs-item-selected="onItemSelected($event.detail)"
  ></snap-tabs>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import type { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";

type TabMenuSelectedItem = {
  index: number;
  name: string;
};

@Options({
  props: {
    type: String,
    routes: Array<DropdownMenuItem>,
  },
})
export default class NavigationTabs extends Vue {
  type?: string;
  routes?: Array<DropdownMenuItem>;

  get options(): Array<DropdownMenuItem> {
    return (this.routes || []).map((item) => ({
      ...item,
      selected: item.value === this.$route.name,
    }));
  }

  onItemSelected(selected: TabMenuSelectedItem) {
    const item = this.routes?.[selected.index];
    if (item) {
      this.$router.push({ name: item.value });
    }
  }
}
</script>

<style scoped lang="scss">
.snap-tabs.snap-tabs--primary {
  overflow-x: hidden;
  scrollbar-width: thin;
}
</style>
