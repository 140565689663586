<template>
  <div>
    <form
      class="flex items-start gap-2 my-4"
      v-if="!isWaitingForConfirmation"
      @submit.prevent="requestUpdate()"
      v-keyboard-submit
    >
      <InputField
        class="block flex-1"
        label="Email"
        name="email"
        type="email"
        v-model="email"
        :validation="v$.email"
      ></InputField>

      <snap-button
        button-type="submit"
        icon-position="left"
        shape="circular"
        size="md"
        variant="primary"
        class="mt-6"
        :disabled="!isChangedEmail || state$.is('SUBMITTING')"
        >Update
      </snap-button>
    </form>

    <form
      class="my-4"
      v-if="isWaitingForConfirmation"
      @submit.prevent="confirmUpdate()"
      v-keyboard-submit
    >
      <Alert type="warning" v-if="!state$.is('SUBMITTING')">
        We’ve sent a verification code to
        <template #description>{{ email }}</template>
      </Alert>

      <div class="flex gap-2 my-4 flex-wrap">
        <div>
          <InputCodeField
            label="Email Verification Code"
            v-model="code"
            :validation="v$.code"
            help-text="Please enter your 6-digit verification code."
          ></InputCodeField>

          <snap-link @click.prevent="requestUpdate(true)"
            >Send New Code
          </snap-link>
        </div>

        <snap-button
          button-type="submit"
          icon-position="left"
          shape="circular"
          size="md"
          variant="primary"
          class="mt-6"
          :disabled="state$.is('SUBMITTING') || code.length < 6"
          >Confirm
        </snap-button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Options } from "vue-class-component";
import { mapGetters } from "vuex";

import { Form, Model, Submit } from "@/core/Form";

import type { User } from "@/types/graphql";
import type { FeatureToggle } from "@/store/treatments.module";

import InputField from "@/components/snap-ui/InputField.vue";
import InputCodeField from "@/components/snap-ui/InputCodeField.vue";
import Alert from "@/components/snap-ui/Alert.vue";

import { $notify } from "@/services/notification";

import { RequiredRule, EmailRule, ValidationCodeRule } from "@/util/validator";
import { userEmailConfirm, userUpdate } from "@/services/user.service";

@Options({
  components: { Alert, InputField, InputCodeField },
  computed: {
    ...mapGetters(["me", "featureOn"]),
  },
})
export default class EmailUpdateForm extends Form {
  me?: User;
  featureOn?: FeatureToggle;

  @Model({
    required: RequiredRule("Email is required."),
    email: EmailRule("Email is invalid"),
  })
  email = "";

  @Model({
    required: RequiredRule("Verification code is required."),
    code: ValidationCodeRule("Verification code is invalid."),
  })
  code = "";

  isWaitingForConfirmation = false;

  mounted() {
    this.email = this.me?.email || "";
  }

  get isChangedEmail(): boolean {
    return this.email !== this.me?.email;
  }

  @Submit({ model: "email" })
  async requestUpdate(ignoreChanges?: boolean): Promise<void> {
    if (
      (ignoreChanges || this.isChangedEmail) &&
      (await this.isPartialValid(["email"]))
    ) {
      await userUpdate({ id: this.me?.id, email: this.email });
      this.isWaitingForConfirmation = true;
    }
  }

  @Submit({ model: "code" })
  async confirmUpdate(): Promise<void> {
    if (await this.isPartialValid(["code"])) {
      await userEmailConfirm(this.code);
      this.isWaitingForConfirmation = false;
      this.$store.commit("updateUserState", { email: this.email });

      $notify({
        title: "Your email has been updated",
        type: "success",
      });
    }
  }
}
</script>
