import { RouteRecordRaw } from "vue-router";

import {
  UserImpersonateCtrl,
  UserImpersonateLogoutCtrl,
} from "@/controllers/UserImpersonate";

import { InviteCtrl } from "@/controllers/Invite";

export const LinkRoutes: Array<RouteRecordRaw> = [
  {
    path: "/link/impersonate/logout",
    name: "link/impersonate_logout",
    component: {},

    beforeEnter: UserImpersonateLogoutCtrl,

    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/link/impersonate/:id",
    name: "link/impersonate",
    component: {},

    beforeEnter: UserImpersonateCtrl,

    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/link/invite/:id",
    name: "link/invite",
    component: {},

    beforeEnter: InviteCtrl,
  },
];
