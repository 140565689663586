import type { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";

export const routes: Array<DropdownMenuItem & { toggle?: string }> = [
  {
    name: "General",
    value: "home",
    text: "General",
  },
  {
    name: "Affiliations",
    value: "profile/affiliations",
    text: "Affiliations",
    toggle: "USER-672-show-users-associations-on-my-profile",
  },
];
