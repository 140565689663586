import { toRaw } from "vue";

export const $id = (prefix = "id", n = 1): string =>
  prefix +
  [...new Array(n)]
    .map(() =>
      Math.floor(1e16 * Math.random() * new Date().getTime()).toString(36)
    )
    .join("");

export const sleep = async (timer: number) =>
  new Promise((resolve) => setTimeout(resolve, timer));

export const debounce = <T extends []>(func: () => void, timeout = 300) => {
  let timer: undefined | number | NodeJS.Timeout;
  return (...args: T) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const getRandomIndex = (from: number, to: number): number =>
  Math.floor(Math.random() * (to - from + 1) + from);

export const getRandomItem = <T>(arr: Array<T>): T =>
  arr[getRandomIndex(0, arr.length - 1)];

export const isURL = (url?: string | null): boolean => {
  if (!url) return false;

  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

export const normalizeObject = (a: object): object =>
  Object.fromEntries(
    Object.entries(a)
      .map(([key, value]) =>
        value && typeof value === "object"
          ? [key, normalizeObject(value)]
          : [key, value]
      )
      .sort(([key1], [key2]) => (key1 > key2 ? 1 : -1))
  );

export const isEqualObjects = (a: object, b: object): boolean =>
  JSON.stringify(normalizeObject(toRaw(a))) ===
  JSON.stringify(normalizeObject(toRaw(b)));

export const isChildEmail = (email?: string): boolean =>
  !!email && /^child-\d+@snapraise.com$/gi.test(email);

export const fullName = (firstName?: string, lastName?: string): string =>
  `${firstName || "‎"} ${lastName || "‎"}`;

export const normalizeEnumValue = (value: string): string => {
  return value
    .split("_")
    .map(
      (str) =>
        str.substring(0, 1).toUpperCase() + str.substring(1).toLowerCase()
    )
    .join(" ");
};

export const normalizeEnum = <T extends string>(
  o: object
): Record<T, string> => {
  return Object.fromEntries(
    Object.entries(o).map((entry) => [
      entry[1] as T,
      normalizeEnumValue(entry[1]),
    ])
  ) as Record<T, string>;
};

export const manageAlertLink = (
  consumer?: string,
  redirectTo?: string
): string | undefined => {
  if (consumer === "manage" && redirectTo) {
    try {
      const redirectUrl = new URL(redirectTo);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, appNamePath, schoolNamePath] = redirectUrl.pathname.split("/");

      if (appNamePath && schoolNamePath && appNamePath == "register") {
        return `https://${redirectUrl.host}/register/${schoolNamePath}/dashboard/catalog`;
      }
    } catch {
      return;
    }
  }
};
