import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { Vue } from "vue-class-component";

import InternalPage from "@/layouts/InternalPage.vue";
import Profile from "@/views/profile/Profile.vue";

Vue.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate",
]);

import { AuthGuard } from "./auth.guard";
import { ChallengesGuard } from "./challenges.guard";
import { ConsumerGuard } from "./consumer.guard";
import { AccessGuard } from "./access.guard";
import { UiGuard } from "./ui.guard";

import { AdminRoutes } from "./admin.routes";
import { ChallengesRoutes } from "./challenges.routes";
import { ExternalRoutes } from "./external.routes";
import { LinkRoutes } from "./link.routes";
import { ParticipantsRoutes } from "./participants.routes";
import { ProfileRoutes } from "./profile.routes";

const routes: Array<RouteRecordRaw> = [
  ...AdminRoutes,
  ...ChallengesRoutes,
  ...ExternalRoutes,
  ...LinkRoutes,
  ...ParticipantsRoutes,
  ...ProfileRoutes,

  {
    path: "/",
    name: "home",
    component: Profile,

    meta: {
      title: "My Profile",
      requiresAuth: true,
      layout: InternalPage,
    },
  },

  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(AuthGuard);
router.beforeEach(ChallengesGuard);
router.beforeEach(ConsumerGuard);
router.beforeEach(AccessGuard);
router.beforeEach(UiGuard);

export default router;
