import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f3246bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "options"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("snap-tabs", {
    type: _ctx.type || 'primary',
    options: _ctx.options,
    onSnapTabsItemSelected: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onItemSelected($event.detail)))
  }, null, 40, _hoisted_1))
}