<template>
  <div class="md:h-full md:flex md:flex-col md:overflow-y-hidden">
    <div
      class="page-content__title bg-white py-4 px-4 sm:px-6 text-left shadow z-10"
    >
      <div class="flex items-center">
        <div class="page-content__icon pr-2">
          <slot name="icon"></slot>
        </div>
        <h1 class="text-xl md:text-2xl font-semibold text-gray-800 flex-1">
          {{ title }}
        </h1>
        <div class="page-content__actions pl-2 flex">
          <slot name="actions"></slot>
        </div>
      </div>

      <NavigationTabs
        class="mt-2 -ml-4 -mb-6 pb-px w-full"
        v-if="routes && routes?.length > 1"
        type="primary"
        :routes="routes"
      ></NavigationTabs>
    </div>

    <div class="md:px-6 md:flex-1 md:overflow-y-auto">
      <div class="mb-2">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import type { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";

import NavigationTabs from "@/components/snap-ui/NavigationTabs.vue";

@Options({
  props: {
    title: String,
    routes: Array<DropdownMenuItem>,
  },
  components: { NavigationTabs },
})
export default class PageContent extends Vue {
  title?: string;
  routes?: Array<DropdownMenuItem>;
}
</script>

<style scoped lang="scss">
.page-content__header:empty,
.page-content__footer:empty,
.page-content__icon:empty,
.page-content__actions:empty {
  display: none;
}

.page-content__actions {
  :slotted(snap-button + snap-button) {
    margin-left: 0.5rem;
  }
}

.page-content__title {
  @media (min-width: 768px) and (max-width: 992px) {
    left: 92px;
  }

  @media (min-width: 992px) {
    left: 286px;
  }
}
</style>
